<template>
  <div>
    <div class="code" v-if="!viewOnly">{{ paragraph.type }}</div>
    <codemirror
      :modelValue="paragraph.content"
      placeholder="Code goes here..."
      :style="{ height: '400px' }"
      :autofocus="false"
      :indent-with-tab="true"
      :tab-size="2"
      :extensions="extensions"
      :options="cmOption"
      :disabled="viewOnly"
      @change="onBlurUpdateContent"
    />
  </div>
</template>

<script>
import { Codemirror } from "vue-codemirror";
import { oneDark } from "@codemirror/theme-one-dark";
import { javascript } from "@codemirror/lang-javascript";
import { html } from "@codemirror/lang-html";
import { css } from "@codemirror/lang-css";

export default {
  name: "DocumentationTextParagraph",
  props: {
    paragraph: Object,
    documentationId: String,
    viewOnly: Boolean,
  },
  components: { Codemirror },
  data() {
    return {
      extensions: [javascript(), css(), html(), oneDark],
      cmOption: {
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        mode: "",
        hintOptions: {
          completeSingle: false,
        },
        keyMap: "sublime",
        matchBrackets: true,
        showCursorWhenSelecting: true,
        theme: "monokai",
        extraKeys: { Ctrl: "autocomplete" },
      },
    };
  },
  mounted() {
    this.cmOption.mode = this.paragraph.type;
  },
  methods: {
    onBlurUpdateContent(content) {
      this.$emit("startsLoading");
      this.$http
        .post("/documentation-paragraph/update", {
          id: this.paragraph.id,
          user_id: this.$store.state.userData.id,
          documentation_id: this.documentationId,
          type: this.paragraph.type,
          content: content,
          order: this.paragraph.order,
        })
        .then(() => {
          this.$emit("savedSuccessfully");
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.code
  text-align: right
  color: #ccc
  margin-top: -8px
  font-size:12px
</style>