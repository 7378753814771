<template>
  <section class="paragraphs">
    <aRow class="meta">
      <aCol :span="24">
        <div>
          <h1>{{ documentation.title }}</h1>
        </div>
        <div class="infos">
          <a-icon type="calendar" /> Criado em:
          {{ documentation.created | formatDateTime }}
          <a-icon class="ml-20" type="edit" /> Atualizado em:
          {{ documentation.updated | formatDateTime }}
          <a-icon class="ml-20" type="user" /> Criado por:
          {{ documentation.user.first_name }} {{ documentation.user.last_name }}
        </div>
      </aCol>
    </aRow>
    <aRow class="content">
      <aCol v-for="(p, i) in paragraphs" :key="i" :span="24">
        <div
        class="text-paragraph"
          v-if="p.type === 'text'"
          v-html="p.content.replace(/<p><br><\/p>/g, '')"
        />
        <div v-if="p.type === 'file'">
          <a class="file-ico" :href="p.content"
            ><a-icon type="file-zip" /> Baixar arquivo</a
          >
        </div>
        <div v-if="p.type === 'image'">
          <img
            class="image"
            :src="p.content"
            @click="openPicture = !openPicture"
          />

          <VueEasyLightbox
            escDisabled
            moveDisabled
            :visible="openPicture"
            :imgs="p.content"
            :index="0"
            :loop="true"
            @hide="openPicture = false"
          ></VueEasyLightbox>
        </div>
        <div v-if="['info', 'warning', 'error', 'success'].includes(p.type)">
          <aAlert class="mb-20 p-alert" :type="p.type" banner>
            <template #message>
              <div v-html="p.content" />
            </template>
          </aAlert>
        </div>

        <div
          class="mb-20"
          v-if="
            [
              'text/javascript',
              'text/html',
              'text/css',
              'text/php',
              'js',
              'php',
              'html',
            ].includes(p.type)
          "
        >
          <DocumentationCodeParagraph
            :documentationId="documentationId"
            :paragraph="p"
            :viewOnly="true"
          />
        </div>
      </aCol>
    </aRow>
  </section>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import DocumentationCodeParagraph from "../paragraphs/DocumentationCodeParagraph.vue";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "DocumentationParagraphsSection",
  components: { DocumentationCodeParagraph, VueEasyLightbox },
  props: {
    paragraphs: Array,
    documentation: Object,
  },
  mixins: [formatThings],
  data() {
    return { openPicture: false };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass">
.p-alert
  p
    margin: 0
iframe.ql-video
  max-width: 740px
  width: 100%
  margin: 0 auto
  height: 480px
  display: block
.text-paragraph
  img
    max-width: 100%
</style>

<style lang="sass" scoped>
.paragraphs
  background: #fff
  margin-bottom: 20px
  display: block
  box-shadow: 0 0 40px #ddd
  padding: 0
  border-radius: 6px
  .meta
    padding:  30px 60px
    border-bottom: 1px solid #eee
    .infos
      font-size: 12px
      color: #999
    h1
      font-size: 40px
      font-weight: 600
      margin: 0 0 14px
      letter-spacing: -1px
      line-height: 1
  .content
    padding: 30px 60px



    .image
      max-width: 100%
      margin-bottom: 20px
</style>
