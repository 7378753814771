var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"paragraphs"},[_c('aRow',{staticClass:"meta"},[_c('aCol',{attrs:{"span":24}},[_c('div',[_c('h1',[_vm._v(_vm._s(_vm.documentation.title))])]),_vm._v(" "),_c('div',{staticClass:"infos"},[_c('a-icon',{attrs:{"type":"calendar"}}),_vm._v(" Criado em:\n        "+_vm._s(_vm._f("formatDateTime")(_vm.documentation.created))+"\n        "),_c('a-icon',{staticClass:"ml-20",attrs:{"type":"edit"}}),_vm._v(" Atualizado em:\n        "+_vm._s(_vm._f("formatDateTime")(_vm.documentation.updated))+"\n        "),_c('a-icon',{staticClass:"ml-20",attrs:{"type":"user"}}),_vm._v(" Criado por:\n        "+_vm._s(_vm.documentation.user.first_name)+" "+_vm._s(_vm.documentation.user.last_name)+"\n      ")],1)])],1),_vm._v(" "),_c('aRow',{staticClass:"content"},_vm._l((_vm.paragraphs),function(p,i){return _c('aCol',{key:i,attrs:{"span":24}},[(p.type === 'text')?_c('div',{staticClass:"text-paragraph",domProps:{"innerHTML":_vm._s(p.content.replace(/<p><br><\/p>/g, ''))}}):_vm._e(),_vm._v(" "),(p.type === 'file')?_c('div',[_c('a',{staticClass:"file-ico",attrs:{"href":p.content}},[_c('a-icon',{attrs:{"type":"file-zip"}}),_vm._v(" Baixar arquivo")],1)]):_vm._e(),_vm._v(" "),(p.type === 'image')?_c('div',[_c('img',{staticClass:"image",attrs:{"src":p.content},on:{"click":function($event){_vm.openPicture = !_vm.openPicture}}}),_vm._v(" "),_c('VueEasyLightbox',{attrs:{"escDisabled":"","moveDisabled":"","visible":_vm.openPicture,"imgs":p.content,"index":0,"loop":true},on:{"hide":function($event){_vm.openPicture = false}}})],1):_vm._e(),_vm._v(" "),(['info', 'warning', 'error', 'success'].includes(p.type))?_c('div',[_c('aAlert',{staticClass:"mb-20 p-alert",attrs:{"type":p.type,"banner":""},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(p.content)}})]},proxy:true}],null,true)})],1):_vm._e(),_vm._v(" "),(
          [
            'text/javascript',
            'text/html',
            'text/css',
            'text/php',
            'js',
            'php',
            'html',
          ].includes(p.type)
        )?_c('div',{staticClass:"mb-20"},[_c('DocumentationCodeParagraph',{attrs:{"documentationId":_vm.documentationId,"paragraph":p,"viewOnly":true}})],1):_vm._e()])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }